import React, {Component} from 'react';
import BannerLinks from '../banner-links';
import {IS_MOBILE} from "../../common/consts";
import './style.scss';

class GreenHeaderBannerLinks extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({...this.state, isMobile: true})
        }
    }

    render() {
        const bannerLinks = !this.state.isMobile && this.props.bannerLinks
        ?
            <BannerLinks
                data={this.props.bannerLinks}
                layout={this.props.bannerLinkLayout}
            />
            :
            null;
        return (
            <section className="banner-section inner" ref={this.props.containerRef ? this.props.containerRef : null}>
                <div className="heading">
                    {this.props.headline && <h1>{this.props.headline}</h1>}
                    {this.props.lightText && <div style={{marginBottom: '50px'}} dangerouslySetInnerHTML={{__html: this.props.lightText}} />}
                    {bannerLinks}
                </div>
            </section>
        )
    }
}

export default GreenHeaderBannerLinks;