import React, {Component} from 'react';
import Layout from '../components/layout';
import {graphql} from "gatsby";
import MidPageInfoBanner from '../components/mid-page-info-banner';
import JoinNetwork from '../components/join-network';
import GreenHeaderBannerLinks from '../components/green-header-with-banner-links';
import {IS_MOBILE, CURR_HEADER} from "../common/consts";
import {videoSectionMapper, resourcesTrioMapper, mapGartner} from '../common/utils';
import queryString from 'query-string';
import GartnerStrip from '../components/gartner-strip';


class SolutionsLobby extends Component {

    constructor(props) {
        super(props)

        this.banner = React.createRef();

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({...this.state, isMobile: true})
        }

        const params = queryString.parse(window.location.search);

        if('row' in params){
            const scrollHeight = this.banner.current.clientHeight - CURR_HEADER;
            window.scrollTo(0, scrollHeight)
        }

        if(this.props.location.hash) {
            setTimeout(() => {
                const anchor = document.querySelector(`${this.props.location.hash}`);

                if(anchor) {
                    anchor.scrollIntoView();
                }
            }, 50)
        }
    }

    generateButtonLinks = (solutionsLobbyAnchorLinks) => {

        return solutionsLobbyAnchorLinks.solutionsAnchorLinks.map((section) => (
            {
                displayName: section.solutionsAnchorSectionTitle || "",
                items: section.solutionsAnchorLinks.map((item) => ({
                    icon: item.solutionsAnchorIcon ? item.solutionsAnchorIcon : null,
                    title: item.solutionsAnchorLabel || "",
                    path: `#${item.solutionsAnchorId}` || ""
                }))
            }
        ))
    }

    generateMidPageData = (midPageInfoSection) => {

        const rows = midPageInfoSection.middlePageTextImageRow;

        if(typeof window !== 'undefined' && window.location.search) {
            const params = queryString.parse(window.location.search);
            if('row' in params){
                const index = rows.findIndex((item, index, arr) => item.middlePageAnchorId === params.row);
                rows.splice(0, 0, rows.splice(index, 1)[0]);
            }
        }

        return rows.map((item, i) => ({
            bannerImg: item.middlePageRowImage ? item.middlePageRowImage : null,
            heading: item.middlePageRowHeadline || "",
            text: item.middlePageRowText || "",
            withCarousel: item.resources && item.resources.length > 0,
            link: item.middlePageLink || null,
            anchorID: item.middlePageAnchorId || null,
            carouselData: item.resources ? item.resources.map((resource) => ({
                link: resource.resourceFields.resourceLink || "",
                img: resource.resourceFields.resourceSmallImage ? resource.resourceFields.resourceSmallImage : null,
                bold: resource.resourceFields.resourceType || "",
                light: resource.title || ""
            })) : null

        }))

    }

    render(){
        const {data} = this.props;
        const {wpPage} = data;
        const {pageSeoTitle, headingWithParagraph, solutionsLobbyAnchorLinks, midPageInfoSection, tryUsStrip, includeGartner, videoSection, resourcesTrioFooter} = wpPage;
        const infoBanners = midPageInfoSection.middlePageTextImageRow.length ? this.generateMidPageData(midPageInfoSection) : null;

        const bannerLinks = solutionsLobbyAnchorLinks.solutionsAnchorLinks.length ? this.generateButtonLinks(solutionsLobbyAnchorLinks) : null;

        const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

        const coolInsights = resourcesTrioFooter.resourcesTrioResources && resourcesTrioFooter.resourcesTrioResources.length ? resourcesTrioMapper( resourcesTrioFooter.resourcesTrioHeadline, resourcesTrioFooter.resourcesTrioResources) : null;

        const stickyBanner = !wpPage.stickyBanner ? false : {
            label: wpPage.stickyBanner.stickyBannerLabel,
            link: wpPage.stickyBanner.stickyBannerLink,
            goldenLabel: wpPage.stickyBanner.goldenButtonText,
            goldenLink: wpPage.stickyBanner.goldenButtonLink,
        };

        const gartner = includeGartner ? mapGartner({gartnerType: includeGartner.gartnerType, gartnerInclude: includeGartner.gartnerInclude}) : null;
        return (
            <Layout className="solutions-lobby"
                    yoastData={wpPage.seo || null}
                    lang={this.props.pageContext.lang}
                    breadcrumbs={[{title: wpPage.title, path: this.props.location.pathname}]}
                    whiteMenu={false}
                    seoTitle={pageSeoTitle.pageSeoTitle}
                    title={wpPage.title}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    translations={this.props.pageContext.translations}
                    wpid={wpPage.databaseId}
                    {...{youtube}}
                    {...{coolInsights}}
                    {...{stickyBanner}}>
                <GreenHeaderBannerLinks
                    headline={headingWithParagraph.headingParagraphHeadline ? headingWithParagraph.headingParagraphHeadline : null}
                    lightText={headingWithParagraph.headingParagraphParagraph ? headingWithParagraph.headingParagraphParagraph : null}
                    bannerLinks={bannerLinks}
                    bannerLinkLayout="sections"
                    containerRef={this.banner}
                />
            <section className="middle-info-banner">
                {infoBanners && infoBanners.map((banner, i) => {
                        return (
                            <>
                            {i === (parseInt((infoBanners.length / 2),10)) && includeGartner.gartnerInclude && includeGartner.gartnerType === 'accordion' &&
                            <GartnerStrip
                                key={'gartner-accordion'}
                                type={'accordion'}
                            />
                            }
                            <MidPageInfoBanner {...banner} key={`min-page-banner-${i}`} imageFirst={Math.abs(i % 2) === 1} offWhite={Math.abs(i % 2) === 1}/>
                            </>
                        )
                    })
                }

            </section>
                {!this.state.isMobile &&
                    <JoinNetwork
                        headline={tryUsStrip.tryUsHeadingText ? tryUsStrip.tryUsHeadingText : undefined}
                        buttonText={tryUsStrip.tryUsButtonText ? tryUsStrip.tryUsButtonText : undefined}
                    />
                }
            </Layout>
        )
    }
}

export default SolutionsLobby;


export const pageQuery = graphql`
    query solutionsLobbyPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            } 
            headingWithParagraph {
                headingParagraphParagraph
                headingParagraphHeadline
            }
            solutionsLobbyAnchorLinks {
                solutionsAnchorLinks {
                    solutionsAnchorLinks {
                        solutionsAnchorIcon {
                                altText
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 84){
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                        }
                                    }
                                    publicURL
                                }
                            }
                            solutionsAnchorLabel
                            solutionsAnchorId
                    }
                }
            }
            midPageInfoSection {
                middlePageTextImageRow {
                    middlePageRowHeadline
                    middlePageRowText
                    middlePageRowImage {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                    middlePageAnchorId
                    middlePageLink
                    resources {
                        ... on WpResource {
                            id
                            title
                            resourceFields {
                                resourceType
                                resourceShortDescription
                                resourceSmallImage {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 504, quality: 100){
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                        extension
                                        publicURL
                                    }
                                }
                                resourceLink
                            }
                        }
                    }
                }
            }
            tryUsStrip {
                tryUsHeadingText
                tryUsButtonText
            }
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`