import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CatoImg from '../cato-image';
import './style.scss';

class BannerLinks extends Component {



    generateLink = (link) => {

        return (
            <a href={link.path} className="banner-link-link">
                <div className="img-wrapper">
                    {link.icon && <CatoImg img={link.icon} alt=""/>}
                </div>
                <div className={'text-wrapper'}>
                    <span className="text" dangerouslySetInnerHTML={{__html: link.title}} />
                </div>
            </a>
        )
    };

    generateSections = () => {
        const {data} = this.props;

        return (
            <div className="banner-links-sections">
                {data.map((section, i) => {
                    return (
                        <div key={`banner-links-section-${i}`} className={`${section.items.length > 3 ? 'add-row' : ''} banner-links-section`}>
                            <h3>{section.displayName}</h3>
                            <ul className={'hide-bullets-style'}>
                                {section.items.map((item,j) => <li key={`banner-link-item-${j}`}>{this.generateLink(item)}</li>)}
                            </ul>
                        </div>
                    )
                })}
            </div>
        )

    }

    generateSingleList = () => {
        const {data} = this.props;

        return(
            <div className="banner-links-sections single">
                <ul className={'hide-bullets-style'}>
                    {data.map((item, i) => {
                        return <li key={`banner-link-${i}`}>{this.generateLink(item)}</li>
                    })}
                </ul>
            </div>
        )
    }

    render() {
        const inner = this.props.layout === 'sections' ? this.generateSections() : this.generateSingleList();
        return (
            <div className={`banner-links-wrapper${this.props.layout === 'list' ? ' single' : ''}`}>
                {inner}
            </div>
        )
    }
}

BannerLinks.propTypes = {
    layout: PropTypes.oneOf(['sections', 'list'])
}

export default BannerLinks;